<template lang="pug">
.container-fluid.px-3.settings-page
  .row.mb-4
    .col
      om-heading.mt-1(h1) {{ $t('globalFrequencyCap.heading') }}
  .row
    .col-1
    .col-10
      .container
        global-frequency-cap(:value="selected" @selectCap="getCapIndex($event)")
        .d-flex.justify-content-center.mt-5
          om-button(primary @click="save(selected)") {{ $t('save') }}
  .col-1
</template>
<script>
  import { mapState } from 'vuex';
  import ADD_EXPERIMENTAL_SETTING from '@/graphql/AddExperimentalSetting.gql';
  import globalFrequencyCap from '@/mixins/globalFrequencyCap';

  export default {
    mixins: [globalFrequencyCap],
    data() {
      return {
        selected: null,
      };
    },
    computed: {
      ...mapState(['account']),
    },
    mounted() {
      this.initSelectedCap();
    },
    methods: {
      getCapIndex($event) {
        this.selected = $event;
      },
      initSelectedCap() {
        const globalFrequencyCap = this.account.settings.experiments.GlobalFrequencyCap;

        if (!globalFrequencyCap) {
          this.selected = 2;
          return;
        }

        this.setByExistingSetting(globalFrequencyCap);
      },
      setByExistingSetting(globalFrequencyCap) {
        const { count, timeUnit, inTime } = globalFrequencyCap;
        const countAndUnitGood = count === 1 && timeUnit === 'minutes';

        if (countAndUnitGood) {
          if (inTime === 1) this.selected = 1;
          if (inTime === 2) this.selected = 0;
        }
      },
      async save(index) {
        const preDefinedCapSettings = [this.safe, this.normal, this.off];

        try {
          await this.$apollo.mutate({
            mutation: ADD_EXPERIMENTAL_SETTING,
            variables: {
              input: preDefinedCapSettings[index],
            },
          });
          this.$notify({
            type: 'success',
            title: this.$t('notifications.saveSuccess'),
          });
        } catch (e) {
          this.$notify({
            type: 'error',
            title: this.$t('notifications.saveError'),
          });
          console.log('error', e);
        }
      },
    },
  };
</script>
<style lang="sass">
  .settings-page
    .heading-2,
    .global-frequency-cap-subtitle
      display: none
</style>
