<template lang="pug">
div
  .row.my-5
    .col-12
      om-heading.text-center(h2 v-html="$t('globalFrequencyCap.title')")
  .row.mt-4
    .col-lg-6.col-12.d-flex.align-items-center.gfc-left
      .row
        .col-2
          img(
            :src="require('@/assets/admin/svg/globalFrequencyCap.svg')"
            style="margin-left: auto"
          )
        div(:class="leftColClasses")
          om-heading.mb-4.global-frequency-cap-subtitle(h3) {{ $t('globalFrequencyCap.subTitle') }}
          om-body-text(bt400md) {{ $t('globalFrequencyCap.description') }}
    .col-lg-6.col-12.gfc-right
      om-radio-select-cards#globalFrequencyCaps(
        :items="items"
        :value="selected"
        @input="setFrequency($event)"
      )
</template>
<script>
  export default {
    props: {
      value: {
        type: [Number, Object],
      },
    },
    data() {
      return {
        items: [
          {
            icon: 'shield-check',
            title: this.$t('globalFrequencyCap.predefinedCaps.safe.title'),
            description: this.$t('globalFrequencyCap.predefinedCaps.safe.description'),
          },
          {
            icon: 'shield',
            title: this.$t('globalFrequencyCap.predefinedCaps.normal.title'),
            description: this.$t('globalFrequencyCap.predefinedCaps.normal.description'),
          },
          {
            icon: 'shield-slash',
            title: this.$t('globalFrequencyCap.predefinedCaps.off.title'),
            description: this.$t('globalFrequencyCap.predefinedCaps.off.description'),
          },
        ],
      };
    },
    computed: {
      selected: {
        get() {
          return this.value;
        },
        set(v) {
          return v;
        },
      },
      leftColClasses() {
        return {
          'col-10': true,
          'py-3': !this.$route.path.includes('settings'),
        };
      },
    },
    methods: {
      emitFrequency(v) {
        this.$emit('selectCap', v);
      },
      setFrequency($event) {
        this.selected = $event;
        this.emitFrequency($event);
      },
    },
  };
</script>
