import GlobalFrequencyCap from '@/components/GlobalFrequencyCap.vue';

export default {
  components: { GlobalFrequencyCap },
  data() {
    return {
      safe: {
        data: {
          count: 1,
          inTime: 2,
          timeUnit: 'minutes',
          limitlessCampaignIds: 'null',
        },
        type: 'GlobalFrequencyCap',
      },
      normal: {
        data: {
          count: 1,
          inTime: 1,
          timeUnit: 'minutes',
          limitlessCampaignIds: 'null',
        },
        type: 'GlobalFrequencyCap',
      },
      off: { data: { reset: true }, type: 'GlobalFrequencyCap' },
    };
  },
};
